.help-index-page {
    .list {
        height: 3rem;
        line-height: 3rem;
        letter-spacing: .15rem;
    }
}

.help-series-page,
.help-handicap-page,
.help-sandbagging-page {
    .divider {
        height: .25rem;
        background: linear-gradient(to right, $dark-blue, white) !important;
        margin-left: -16px;
        margin-right: -16px;
    }
}

.help-series-page {

    .help-series-table-page {
        .series-table {
            div.v-data-table__wrapper:not(.auto-height) {
                height: calc(100vh - 232px);
                overflow-y: auto;
            }

            table {

                thead tr {

                    th {
                        position: sticky;
                        top: 0;
                        background-color: $dark-blue;

                        &:first-child {
                            z-index: 2;
                            left: 0;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            &.fixed {
                                position: sticky;
                                left: 0;
                            }
                        }

                        &.c {
                            td {
                                background-color: $c-bg;

                                &.fixed {
                                    color: $c-font;
                                }
                            }
                        }

                        &.b {
                            td {
                                background-color: $b-bg;

                                &.fixed {
                                    color: $b-font;
                                }
                            }
                        }

                        &.a {
                            td {
                                background-color: $a-bg;

                                &.fixed {
                                    color: $a-font;
                                }
                            }
                        }

                        &.s {
                            td {
                                background-color: $s-bg;

                                &.fixed {
                                    color: $s-font;
                                }
                            }
                        }
                    }
                }

                th,
                td {
                    white-space: nowrap;
                    text-align: center !important;
                    border-right: 1px solid white !important;
                    border-bottom: 1px solid white !important;
                }
            }
        }

        .download-warp {
            padding: 16px;
            background-color: white;
            position: absolute;
            top: -9999px;
        }
    }

    .help-series-introduce-page {

        .type-item {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 65%;
            background-image: url("../images/league_logo.png");
            position: relative;

            &:first-child {
                background-image: url("../images/fido_logo.png");
            }

            table {
                margin: 0 auto;

                tr {
                    td {
                        &:nth-child(2) {
                            width: 30px;
                        }

                        &:first-child,
                        &:last-child {
                            max-width: calc((100% - 30px) / 2);
                        }

                        svg {
                            height: 100%;
                            position: absolute;

                            path {
                                stroke: $font-color-base;
                                stroke-width: 1px;
                                fill-opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .ex.v-card {
        border: none;
        background: linear-gradient(to right, $dark-blue, $grey-blue) !important;
        border-radius: 8px;
        padding: 2px;

        .v-toolbar {
            background: inherit;
        }

        .v-card__text {
            background: white;
            border-bottom-left-radius: 6px !important;
            border-bottom-right-radius: 6px !important;
        }
    }
}

.help-handicap-page {

    .help-handicap-01Game-page,
    .help-handicap-cricket-page {
        .v-data-table {
            background: linear-gradient(to right, $dark-blue, $grey-blue) !important;
            border-radius: 0;
            table-layout: fixed;

            td,
            th {
                border-bottom-width: 0 !important;
                padding-left: 1px;
                padding-right: 0;
                padding-bottom: 1px;

                &:last-child {
                    padding-right: 1px;
                }

                div {
                    height: 31px;
                    line-height: 33px;
                }
            }

            tbody tr td {

                div {
                    background-color: white;
                }
            }
        }
    }
}

.normal-game-detail-page {
    .no-data {
        font-size: 2.4rem;
        font-weight: 700;
        text-align: center;
        line-height: 1.6rem;
        margin: 1rem auto;

        .msg {
            font-size: .75rem;
            font-weight: normal;
        }
    }

    .index-page,
    .detail-table {
        table {
            th {
                background-color: $dark-blue;
            }

            td {
                background-color: white;
            }

            th,
            td {
                white-space: nowrap;
                text-align: center !important;
                border-right: 1px solid white !important;
                border-bottom: 1px solid white !important;
            }
        }
    }

    .index-page {
        th {
            width: 40%;
        }
    }
}
