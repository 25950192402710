.text-dark-blue {
    color: $dark-blue;
}

.help-index-page {
    .list {
        a {
            color: $font-color-base;
        }
    }
}

.help-series-page {
    .help-series-table-page {
        .series-table table {
            thead tr {
                th {
                    color: white !important;
                }
            }

            tbody tr td {
                &.fixed {
                    font-size: 1.25rem;
                    font-weight: 500;
                }
            }
        }
    }

    .help-series-introduce-page {}

    .ex.v-card {

        .v-toolbar,
        .v-card__title {
            color: white;
        }
    }
}

.help-handicap-page {

    .help-handicap-01Game-page,
    .help-handicap-cricket-page {
        .v-data-table {
            thead tr {
                th {
                    color: white !important;
                }
            }
        }
    }
}

.normal-game-detail-page {
    .index-page, .detail-table {
        table {
            th {
                color: white !important;
                font-size: .75rem;
            }

            td {
                color: rgb(73, 85, 128);
                font-size: .875rem;
            }
        }
    }
}
